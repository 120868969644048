<template>
    <div class="card-block">
        <el-card shadow="never">
            <el-form :model="search">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="交易单号">
                            <el-input v-model="form.trade_no" placeholder="订单号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="交易时间">
                            <el-date-picker v-model="form.datetime" type="datetimerange" range-separator="至"
                                value-format="YYYY-MM-DD HH:mm:ss" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="primary" id="search" @click="searchData">
                            <el-icon>
                                <Search />
                            </el-icon>
                            搜索
                        </el-button>
                        <el-button type="primary" id="search" @click="download">
                            <el-icon>
                                <Download />
                            </el-icon>
                            导出
                        </el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
    <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column prop="merchant_no" label="商户号" />
        <el-table-column prop="trade_no" label="订单号" width="220" />
        <el-table-column prop="serial_number" label="设备号" width="220" />
        <el-table-column prop="payment_channel" label="支付通道" />
        <el-table-column prop="payment_channel_code" label="通道代码" />
        <el-table-column prop="payment_method" label="支付方式" />
        <el-table-column prop="pay_amount" label="支付金额" />
        <el-table-column prop="status_format" label="支付状态" />
        <el-table-column prop="updated_at" label="创建时间" width="120">
            <template #default="scope">
                <span>{{ dayjs(scope.row.created_at).format("YYYY-MM-DD HH:ss:mm") }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" />
    </el-table>
    <div class="pagination">
        <el-pagination :page-size="pagination.count" layout="prev, pager, next" :total="pagination.total"
            @current-change="handleSizeChange" />
    </div>
</template>

<style lang="less" scoped>
.card-block {
    margin-bottom: 20px;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 10px 0;
}
</style>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import dayjs from "dayjs"
import { Search, Download } from '@element-plus/icons-vue'

const { proxy } = getCurrentInstance()

onMounted(() => {
    getTableData(serchDate.value)
})
const loading = ref(true)
const tableData = ref([])
const pagination = ref({
    total: 0,
    count: 0,
})

const getTableData = async (data) => {
    loading.value = true
    // console.log(data)
    await proxy.$api.getTradeData(data).then(res => {
        if (res.status === 200 && res.success) {
            tableData.value = res.data
            pagination.value = res.pagination
        }
        loading.value = false
    })
}

const handleSizeChange = (val) => {
    serchDate.value.page = val
    getTableData(serchDate.value)
}

const searchData = () => {
    mergeParams()
    serchDate.value.page = 1
    getTableData(serchDate.value)
}

const download = () => {
    mergeParams()
    const filename = '订单明细表' + dayjs().format('YYYYMMDD') + '.xlsx'
    proxy.$api.export(serchDate.value, filename)
}

const mergeParams = () => {
    if (form.value.datetime && form.value.datetime.length == 2) {
        serchDate.value.created_start = form.value.datetime[0]
        serchDate.value.created_end = form.value.datetime[1]
    }
    serchDate.value = Object.assign(form.value, serchDate.value)
}

const getFullTime = (time) => {
    return time < 10 ? "0" + time.toString() : time.toString()
}

const date = new Date()
const y = getFullTime(date.getFullYear())
const m = getFullTime(date.getMonth() + 1)
const d = getFullTime(date.getDate())

const serchDate = ref({
    created_start: y + '-' + m + '-' + d + ' 00:00:00',
    created_end: y + '-' + m + '-' + d + ' 23:59:59',
    page: 1
})

let form = ref({})
</script>